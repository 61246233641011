@import url(https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
}
.MainNavigation_header__2lqQ1 {
  width: 100%;
  height: 5rem;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.MainNavigation_logo__1TgMx {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  color: white;
  margin: 0;
}

.MainNavigation_header__2lqQ1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.MainNavigation_header__2lqQ1 li {
  margin: 0 1rem;
}

.MainNavigation_header__2lqQ1 a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.MainNavigation_header__2lqQ1 button {
  font: inherit;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
}

.MainNavigation_header__2lqQ1 a:hover {
  color: #c291e2;
}

.MainNavigation_header__2lqQ1 button:hover {
  background-color: #c291e2;
  color: #38015c;
}
.ProfileForm_form__2xGgc {
  width: 95%;
  max-width: 25rem;
  margin: 2rem auto;
}

.ProfileForm_control__2YUZH {
  margin-bottom: 0.5rem;
}

.ProfileForm_control__2YUZH label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #353336;
  display: block;
}

.ProfileForm_control__2YUZH input {
  display: block;
  font: inherit;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #38015c;
  padding: 0.25rem;
  background-color: #f7f0fa;
}

.ProfileForm_action__opJ_6 {
  margin-top: 1.5rem;
}

.ProfileForm_action__opJ_6 button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: #38015c;
  color: white;
  border: 1px solid #38015c;
}

.ProfileForm_action__opJ_6 button:hover {
  background-color: #540d83;
  border-color: #540d83;
}
.UserProfile_profile__gQOnH {
  margin: 3rem auto;
  text-align: center;
}

.UserProfile_profile__gQOnH h1 {
  font-size: 5rem;
}
.AuthForm_auth__MPw6W {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #38015c;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color: white;
}

.AuthForm_auth__MPw6W h1 {
  text-align: center;
  color: white;
}

.AuthForm_control__2fVSU {
  margin-bottom: 0.5rem;
}

.AuthForm_control__2fVSU label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.AuthForm_control__2fVSU input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.AuthForm_actions__1VNhY {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AuthForm_actions__1VNhY button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #9f5ccc;
  border: 1px solid #9f5ccc;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.AuthForm_actions__1VNhY button:hover {
  background-color: #873abb;
  border-color: #873abb;
}

.AuthForm_actions__1VNhY .AuthForm_toggle__21tnB {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.AuthForm_actions__1VNhY .AuthForm_toggle__21tnB:hover {
  background-color: transparent;
  color: #ae82cc;
}
.StartingPageContent_starting__kwCvL {
  margin: 3rem auto;
  text-align: center;
}

.StartingPageContent_starting__kwCvL h1 {
  font-size: 5rem;
}
